@charset "utf-8";

/* http://meyerweb.com/eric/tools/css/reset/ */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: 'Expressway', sans-serif;
  vertical-align: baseline;
  /* by bruno */
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  /* padronizar o width contando pelas bordas */
  -webkit-font-smoothing: subpixel-antialiased !important;
  /* renderizar melhor as fontes no chrome */
  /* fim */
}

/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* by bruno */

input,
textarea,
select {
  margin: 0;
  padding: 0;
  border: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

select {
  cursor: pointer;
  /*esconde setinha chrome e firefox by bruno*/
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

/*esconde setinha IE by bruno*/

select::-ms-expand {
  display: none;
}

button {
  background: none;
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;
  display: block;
}

/* micro clearfix */

.clearfix:before,
.clearfix:after {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
}

/* fim clearfix */

.transition {
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.left {
  float: left;
}

.right {
  float: right;
}

.opacity {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  opacity: 0;
}

img,
a {
  display: block;
}

a {
  text-decoration: none;
}

/* fim */

/********** fim css reset **********/

body {
  position: relative;
  width: 100%;
  font-family: 'Expressway', sans-serif;
}

button {
  font-family: 'Expressway', sans-serif;
}

input {
  font-family: 'Expressway', sans-serif;
}

textarea {
  font-family: 'Expressway', sans-serif;
}

.corpo {
  position: relative;
  width: 960px;
  margin: 0 auto;
}

/*Home*/

.cabecalho {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}

.cab-pt01 {
  width: 100%;
  height: 40px;
  background-color: #fff;
  margin-bottom: 15px;
}

.cab-tel {
  font-size: 15px;
  line-height: 40px;
  color: #9c9c9b;
}

.cab-tel span {
  color: #05bde4;
}

.cab-form-search {
  position: relative;
}

.cab-form-search input {
  width: 300px;
  height: 40px;
  font-size: 15px;
  color: #9c9c9b;
  background-color: #f1f1f1;
  padding: 0 40px 0 10px;
}

.lupa {
  position: absolute;
  z-index: 10;
  top: 0px;
  right: 0px;
  font-size: 19px;
  color: #05bde4;
  width: 40px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  font-weight: 700;
}

.cab-form-search button {
  position: absolute;
  z-index: 20;
  top: 0px;
  right: 0px;
  font-size: 15px;
  width: 40px;
  height: 40px;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  opacity: 0;
}

.cab-form-search button:hover + .lupa {
  color: #505050;
}

.cab-face {
  font-size: 24px;
  color: #05bde4;
  line-height: 40px;
  margin-left: 15px;
}

.cab-face:hover {
  color: #505050;
}

.cab-logo a {
  width: 238px;
  height: 82px;
  background: url("../../assets/site/img/logo.png") center no-repeat;
}

.menu {
  padding: 26px 0;
}

.menu li {
  margin-left: 15px;
}

.menu li:first-child {
  margin-left: 0;
}

.menu a {
  font-size: 13px;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
}

.link-ativo {
  color: #505050 !important;
  font-weight: 700;
}

.menu a:hover {
  color: #505050 !important;
}

.menu li:last-child a {
  width: 70px;
  background-color: #05bde4;
  padding: 10px 0;
  margin-top: -10px;
}

.menu li:last-child a:hover {
  background-color: #505050 !important;
  color: #fff !important;
}

.fundos-carousel {
  position: relative;
  width: 100%;
}

.fundo-carousel {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 750px;
  display: none;
}

.fundo-carousel-ativo {
  display: block;
}

.fundo-carousel01 {
  background: url("../../assets/site/img/img01-carousel.jpg") center no-repeat;
}

.fundo-carousel02 {
  background: url("../../assets/site/img/img02-carousel.jpg") center no-repeat;
}

.fundo-carousel03 {
  background: url("../../assets/site/img/img03-carousel.jpg") center no-repeat;
}

.fundo-carousel-setembro-dourado {
  background: url("../../assets/site/img/fundo-erro.jpg") center no-repeat;
}

.fundo-carousel-setembro-dourado a {
  background-color: #F57196 !important;
}

.corpo-carousel {
  position: relative;
  width: 960px;
  height: 100%;
  margin: 0 auto;
}

.info-carousel {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 0;
  width: 380px;
  height: auto;
  -webkit-transform: translate(0%, -50%);
  -moz-transform: translate(0%, -50%);
  -ms-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
}

.info-carousel h2 {
  font-size: 68px;
  color: #fff;
  text-transform: uppercase;
}

.info-carousel p {
  font-size: 15px;
  color: #fff;
  line-height: 18px;
}

.info-carousel a {
  display: inline-block;
  padding: 0 30px;
  height: 45px;
  line-height: 45px;
  font-size: 15px;
  color: #fff;
  background-color: #05bde4;
  text-transform: uppercase;
  margin-top: 15px;
}

.info-carousel a:hover {
  background-color: #505050;
}

.section-banners {
  position: relative;
}

.bolinhas {
  position: absolute;
  z-index: 20;
  bottom: 25px;
  left: 50%;
  -webkit-transform: translate(-50%, 0%);
  -moz-transform: translate(-50%, 0%);
  -ms-transform: translate(-50%, 0%);
  transform: translate(-50%, 0%);
}

.bolinha {
  width: 15px;
  height: 15px;
  background-color: #e6e2cf;
  border: 2px solid #e6e2cf;
  border-radius: 50%;
  margin-left: 10px;
}

.bolinha:first-child {
  margin-left: 0;
}

.bolinha:hover,
.bolinha-ativa {
  background-color: #05bde4;
}

.arrow {
  position: absolute;
  z-index: 20;
  top: 50%;
  -webkit-transform: translate(0%, -50%);
  -moz-transform: translate(0%, -50%);
  -ms-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
  font-size: 60px;
  color: #fafafa;
}

.arrow-left {
  left: 50px;
}

.arrow-right {
  right: 50px;
}

.arrow:hover {
  color: #05bde4;
}

.section-novidades-home {
  background-color: #e6e2cf;
  padding: 50px 0;
  border-bottom: 5px solid #05bde4;
}

.engloba-novidades-home,
.engloba-galeria-home {
  width: 420px;
}

.titulo-section {
  font-size: 65px;
  color: #db1d5b;
  text-transform: uppercase;
  margin-bottom: 40px;
  font-weight: 700;
}

.titulo-section-valores {
  font-size: 35px;
  color: #db1d5b;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-weight: 700;
}

.engloba-valores-home {
  width: 320px;
}

.texto-valores p {
  font-size: 14px;
  line-height: 20px;
}

.pai-info-novi-home {
  width: 285px;
  margin-left: 15px;
}

.pai-info-novi-home-plus {
  width: 100%;
  margin-left: 0;
}

.pai-info-novi-home h4 {
  font-size: 25px;
  color: #313131;
  text-transform: uppercase;
  font-weight: 700;
}

.data-novi-home {
  font-size: 15px;
  color: #313131;
  font-weight: 700;
  margin-bottom: 15px;
}

.info-novi-home {
  font-size: 15px;
  color: #313131;
  line-height: 18px;
}

.estilo-btn {
  display: inline-block;
  padding: 0 30px;
  height: 45px;
  line-height: 45px;
  font-size: 15px;
  color: #fff;
  background-color: #05bde4;
  text-transform: uppercase;
  margin-top: 15px;
}

.estilo-btn-plus {
  padding: 0;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.estilo-btn:hover {
  background-color: #505050;
}

.cada-novidade-home {
  margin-bottom: 50px;
}

.cada-novidade-home:last-child {
  margin-bottom: 0;
}

.engloba-img-home {
  position: relative;
}

.over-img {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(5, 189, 228, 0.8);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  opacity: 0;
}

.engloba-img-home:hover .over-img {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  opacity: 1;
}

.pai-info-over-img {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 0;
  width: 100%;
  -webkit-transform: translate(0%, -50%);
  -moz-transform: translate(0%, -50%);
  -ms-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
}

.pai-info-over-img div {
  width: 100%;
  text-align: center;
  font-size: 40px;
  color: #fff;
}

.pai-info-over-img p {
  font-size: 18px;
  color: #fff;
  text-transform: uppercase;
  margin-top: 15px;
  text-align: center;
}

.engloba-img-home-plus {
  margin: 20px 5px 0 5px;
}

.engloba-img-home-plus div {
  font-size: 30px;
}

.engloba-img-home-plus p {
  font-size: 13px;
  margin-top: 15px;
}

.section-parceiros-home {
  width: 100%;
  height: 290px;
  background: url("../../assets/site/img/fundo-parceiros-home.jpg") center no-repeat;
}

.top-parceiros h2 {
  font-size: 45px;
  color: #fff;
  font-weight: 700;
  margin-top: 20px;
  text-transform: uppercase;
}

.top-parceiros a {
  margin-top: 20px;
}

.section-facebook-home {
  width: 100%;
  background-color: #05bde4;
  padding: 50px 0;
}

.info-face-home {
  width: 345px;
  margin-top: 80px;
}

.info-face-home .fa-facebook-square {
  font-size: 135px;
  color: #fff;
  margin-bottom: 10px;
}

.face-home-txt {
  font-size: 65px;
  color: #fff;
  text-transform: uppercase;
}

.face-home {
  width: 500px;
  height: 500px;
}

.rod-pt01 {
  padding: 20px 0;
  background-color: #fff;
}

.rod-pt02 {
  height: 40px;
  background-color: #505050;
}

.txt-rod {
  font-size: 12px;
  color: #fff;
  text-transform: uppercase;
  padding-top: 16px;
}

.wp8 {
  display: block;
  background: url("../../assets/site/img/wp8.png") right center no-repeat;
  width: 40px;
  height: 12px;
  margin-top: 15px;
}

.wp8:hover {
  width: 135px;
}



.info-rod p {
  font-size: 18px;
  color: #646363;
  text-transform: uppercase;
}

.info-rod p:first-child {
  margin-top: 0;
}

.info-rod .fa {
  margin-right: 10px;
  color: #05bde4;
}

.cada-info-rod {
  margin-top: 22px;
}

.cada-info-rod:first-child {
  margin-top: 0;
}

.engloba-info-rod {
  margin-top: 17px;
}

.doe-rod {
  width: 225px;
}

.doe-rod p {
  font-size: 35px;
  color: #d43e6f;
  text-transform: uppercase;
  font-weight: 700;
}

.link-doe-rod {
  width: 100%;
  text-align: center;
  height: 30px;
  line-height: 30px;
  font-size: 15px;
  color: #fff;
  background-color: #d43e6f;
  text-transform: uppercase;
  margin-top: 5px;
}

.aux-margin {
  margin-left: 100px;
}

.link-doe-rod:hover {
  background-color: #505050;
}

/*Sobre*/

.section-inicial {
  width: 100%;
  height: 460px;
}

.section-inicial-sobre {
  background: url("../../assets/site/img/fundo-sobre.jpg") center no-repeat;
}

.section-inicial .corpo {
  height: 100%;
}

.titulo-internas {
  font-size: 65px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  padding-top: 200px;
}

.section-sobre {
  padding: 20px 0 50px 0;
  background-color: #e6e2cf;
}

.breadcrumb {
  margin-bottom: 30px;
}

.breadcrumb a {
  font-size: 11px;
  color: #565555;
  text-transform: uppercase;
  font-weight: 700;
}

.breadcrumb a:hover {
  text-decoration: underline;
}

.breadcrumb p {
  font-size: 11px;
  color: #db1d5b;
  text-transform: uppercase;
  font-weight: 700;
}

.breadcrumb p:last-child {
  width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.breadcrumb span {
  color: #565555;
}

.plus-bread {
  color: #fff !important;
}

.titulo-section-plus {
  margin-bottom: 10px;
}

.titulo-section-plus4 {
  margin: 60px 0 10px 0;
}

.txt-sobre {
  font-size: 14px;
  color: #000000;
  line-height: 20px;
  margin-bottom: 30px;
}

.cada-person {
  width: 190px;
  margin-left: 65px;
}

.cada-person:first-child {
  margin-left: 0;
}

.nome {
  font-size: 20px;
  color: #4e4d46;
  text-align: center;
  text-transform: uppercase;
  margin: 10px 0 5px 0;
}

.funcao {
  font-size: 14px;
  color: #db1d5b;
  text-align: center;
}

.cada-linha-pessoas {
  margin-top: 20px;
}

.section-voluntarios {
  background-color: #8f113a;
  padding: 50px 0;
}

.titulo-section-plus2 {
  margin-bottom: 10px;
  color: #fff;
}

.txt-voluntarios {
  font-size: 14px;
  color: #fff;
  line-height: 20px;
}

.nome-plus {
  color: #fff;
}

.funcao-plus {
  color: #f6d705;
}

.final-voluntarios {
  margin-top: 100px;
}

.txt-final-voluntarios {
  font-size: 65px;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
}

.form-voluntarios {
  width: 500px;
}

.label-voluntarios {
  display: block;
  font-size: 18px;
  color: #fff;
  margin-top: 10px;
  text-transform: uppercase;
}

.input-voluntarios {
  width: 100%;
  height: 40px;
  border: 2px solid #f6d705;
  background-color: #f6d705;
  margin-top: 5px;
  padding: 0 15px;
  font-size: 15px;
  color: #f68d05;
}

.submit-voluntarios {
  width: 100%;
  height: 40px;
  font-size: 18px;
  color: #fff;
  background-color: #ff5003;
  text-transform: uppercase;
}

.submit-voluntarios:hover {
  background-color: #505050;
}

.half {
  width: 48%;
}

.pai-button {
  position: relative;
  width: 100%;
  margin-top: 25px;
}

.warning {
  font-size: 13px;
  line-height: 16px;
  color: #fff;
  text-transform: uppercase;
  display: none;
}

.show-warning {
  display: block !important;
}

.section-doar-sobre {
  width: 100%;
  height: 620px;
  background: url("../../assets/site/img/fundo-sobre2.jpg") center no-repeat;
  padding: 152px;
}

.section-doar-sobre .corpo {
  height: 100%;
}

.link-doar-sobre {
  width: 270px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  color: #2e2d2e;
  background-color: #f6d705;
  text-transform: uppercase;
  margin-top: 15px;
}

.link-doar-sobre:hover {
  color: #fff;
  background-color: #505050;
}

/*Projetos Sociais*/

.section-inicial-projetos {
  background: url("../../assets/site/img/fundo-projetos.jpg") center no-repeat;
}

.section-projetos {
  padding: 20px 0 50px 0;
  background-color: #444444;
}

.txt-projeto,
.pai-txt-projeto,
.pai-txt-projeto p {
  font-size: 14px;
  color: #fff;
  line-height: 20px;
  margin-bottom: 15px;
}

.cada-lista {
  position: relative;
  width: 311px;
  margin: 15px 4px 0 4px;
  padding: 8px 8px 20px 8px;
}

.cor01 {
  background-color: #cb2358;
}

.cor02 {
  background-color: #19d6e2;
}

.cor03 {
  background-color: #b523cb;
}

.cor04 {
  background-color: #93c221;
}

.cor05 {
  background-color: #e29d19;
}

.cada-lista h3 {
  font-size: 23px;
  color: #fff;
  margin: 15px 0 10px 0;
  text-transform: uppercase;
  height: 47px;
  overflow-y: hidden;
  text-align: center;
  padding-top: 5px;
}

.cada-lista p {
  font-size: 14px;
  color: #fff;
  line-height: 18px;
  height: 163px;
  overflow-y: hidden;
}

.over-cada-lista {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  opacity: 0;
}

.link-cada-lista {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  width: 50px;
  text-align: center;
  height: 50px;
  line-height: 50px;
  font-size: 30px;
  color: #db1d5b;
  background-color: #fff;
  border-radius: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.cada-lista:hover .over-cada-lista {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  opacity: 1;
}

/*Projetos Sociais interna*/

.section-projetos-interna {
  padding: 20px 0 20px 0;
  background-color: #444444;
}

.section-inicial-projetos-interna {
  background: url("../../assets/site/img/fundo-projetos-interna.jpg") center no-repeat;
}

.img-projeto {
  display: block;
  width: auto;
  height: 100%;
  margin: 0 auto;
  margin-bottom: 30px;
}

/*Fotos*/

.section-inicial-fotos {
  background: url("../../assets/site/img/fundo-fotos.jpg") center no-repeat;
}

.cada-lista-fotos {
  position: relative;
  width: 311px;
  margin: 15px 4px 0 4px;
  padding: 8px 8px 20px 8px;
}

.cada-lista-fotos h3 {
  font-size: 23px;
  color: #fff;
  margin: 15px 0 10px 0;
  text-transform: uppercase;
  max-height: 28px;
  overflow-y: hidden;
  padding-top: 5px;
}

.cada-lista-fotos p {
  font-size: 14px;
  color: #fff;
  text-transform: uppercase;
}

.cada-lista-fotos:hover .over-cada-lista {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  opacity: 1;
}

.form-filtro {
  margin: 30px 0;
}

.label-filtro {
  display: block;
  font-size: 22px;
  color: #2ae9eb;
  text-transform: uppercase;
  margin-right: 20px;
}

.select-filtro {
  display: block;
  padding-right: 20px;
  font-size: 13px;
  color: #f7fa00;
  background-color: #444444;
  text-transform: uppercase;
  background: url("../../assets/site/img/seta-select.png") right center no-repeat;
  letter-spacing: 1px;
}

.select-filtro option {
  background-color: #444444;
}

.submit-filtro {
  width: 50px;
  height: 22px;
  background-color: #19d6e2;
  color: #fff;
  margin-left: 20px;
}

.submit-filtro:hover {
  background-color: #db1d5b;
}

.select-geral {
  margin-right: 50px;
}

.select-filtro-plus {
  margin-left: 10px;
}

.pai-pagination {
  margin-top: 25px;
}

.pai-pagination a {
  font-size: 16px;
  color: #fff;
  width: 20px;
  text-align: center;
}

.pai-pagination a:hover,
.pagination-ativo {
  color: #93c221 !important;
}

/*fotos interna*/

.section-inicial-fotos-int {
  background: url("../../assets/site/img/fundo-projetos-interna.jpg") center no-repeat;
}

.data-galeria {
  font-size: 13px;
  color: #f7fa00;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.txt-galeria {
  font-size: 14px;
  color: #fff;
  line-height: 20px;
  margin: 30px 0;
}

.engloba-carousel-thumb-galeria {
  position: relative;
  margin-top: 10px;
}

.carousel-thumb-galeria {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.slider-carousel-thumb-galeria {
  position: relative;
  left: 0;
}

.img-carousel-thumb-galeria {
  width: 145px;
  height: 79px;
  margin: 0 7px;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.img-carousel-thumb-galeria img {
  display: block;
  margin: 0 auto;
  height: 100%;
  width: auto;
}

.seta-carousel-thumb-galeria {
  position: absolute;
  z-index: 10;
  top: 50%;
  font-size: 28px;
  color: #fff;
  width: 25px;
  height: 50px;
  background-color: #a92f52;
  margin-top: -25px;
}

.seta-esq-car-thumb-galeria {
  left: -18px;
}

.seta-dir-car-thumb-galeria {
  right: -12px;
}

.engloba-carousel-galeria {
  position: relative;
  width: 960px;
  height: 523px;
}

.seta-esq-car-galeria {
  left: -25px;
}

.seta-dir-car-galeria {
  right: -25px;
}

.pai-img-carousel-galeria {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: none;
}

.img-carousel-galeria-ativo {
  display: block;
}

.img-carousel-galeria {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: auto;
  max-height: 523px;
}

.fundo-preto {
  position: absolute;
  z-index: 10;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.7);
}

.compartilhar {
  margin: 10px;
}

.section-galerias {
  width: 100%;
  background-color: #59265e;
  padding: 40px 0;
}

.titulo-section-plus3 {
  color: #fff;
  margin-bottom: 20px;
}

.link-plus {
  margin-right: 7px;
}

/*Notícias*/

.section-inicial-noticias {
  background: url("../../assets/site/img/fundo-projetos.jpg") center no-repeat;
}

.cada-lista h6 {
  font-size: 14px;
  color: #fff;
  margin-bottom: 20px;
}

.sem-imagem {
  height: 371px !important;
}

/*Notícias interna*/

.section-inicial-noticias-int {
  background: url("../../assets/site/img/fundo-projetos-interna.jpg") center no-repeat;
}

.txt-noticia {
  font-size: 14px;
  color: #fff;
  line-height: 20px;
  margin-top: 40px;
  margin-bottom: 15px;
}

/*erro*/

.section-erro {
  position: relative;
  width: 100%;
  height: 759px;
  background: url("../../assets/site/img/fundo-erro.jpg") center no-repeat;
}

.pai-txt-erro {
  padding-top: 300px;
}

.erro-404 {
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  text-transform: uppercase;
}

.erro-404 a {
  display: inline;
  color: #fcff08;
}

.erro-404 a:hover {
  text-decoration: underline;
}

/*Contato*/

.section-inicial-contato {
  background: url("../../assets/site/img/fundo-projetos-interna.jpg") center no-repeat;
}

.engloba-txt-contato {
  width: 390px;
}

.txt-contato {
  font-size: 14px;
  color: #000;
  line-height: 18px;
}

.txt-contato-seminario {
  text-align: center;
  margin-bottom: 10px;
}

.txt-contato-seminario a {
  display: inline;
  color: #000;
  text-decoration: underline;
}

.end-contato {
  margin-top: 30px;
  font-size: 20px;
  color: #393939;
  text-transform: uppercase;
}

.end-contato span {
  display: inline-block;
  color: #db1d5b;
  margin-top: 20px;
}

.end-contato-lower {
  text-transform: none;
}

.label {
  display: block;
  font-size: 18px;
  color: #393939;
  margin-top: 10px;
  text-transform: uppercase;
}

.radio {
  margin-top: 10px;
}

.radio label {
  display: inline;
}

.input {
  width: 100%;
  height: 40px;
  border: 2px solid #696969;
  background-color: #696969;
  margin-top: 5px;
  padding: 0 15px;
  font-size: 15px;
  color: #fff;
}

.submit {
  width: 100%;
  height: 40px;
  font-size: 18px;
  color: #fff;
  background-color: #db1d5b;
  text-transform: uppercase;
}

.submit:hover {
  color: #000;
  background-color: #f6d705;
}

.textarea {
  height: 175px;
  resize: none;
  padding-top: 10px;
}

.warning-plus {
  color: #db1d5b;
}

.marca-erro {
  border: 1px solid #ff2700;
}

.engloba-mapa {
  position: relative;
}

.como-chegar {
  width: 100%;
  height: 45px;
  background-color: #696969;
}

.como-chegar h3 {
  font-size: 20px;
  color: #fff;
  line-height: 45px;
  text-transform: uppercase;
}

.form-chegar {
  padding: 7px 0;
}

.input-chegar {
  width: 500px;
  height: 30px;
  background-color: #252525;
  border-radius: 2px;
  padding: 0 15px;
  color: #fff;
}

.submit-chegar {
  text-transform: uppercase;
  width: 140px;
  height: 30px;
  line-height: 28px;
  font-size: 12px;
  color: #fff;
  border: 1px solid #fff;
  margin-left: 20px;
}

.submit-chegar:hover {
  color: #000;
  border: 1px solid #f6d705;
  background-color: #f6d705;
}

#map_canvas {
  position: relative;
  width: 100%;
  height: 600px;
}

/*Parceiros*/

.section-inicial-parceiros {
  background: url("../../assets/site/img/fundo-parceiro.jpg") center no-repeat;
}

.section-parceiros {
  padding: 50px 0;
  background-color: #24809b;
}

.parc-esq {
  width: 290px;
}

.txt-parceiros {
  font-size: 14px;
  color: #fff;
  line-height: 20px;
}

.textarea-parceiros {
  height: 175px;
  resize: none;
  padding-top: 10px;
}

.logos {
  padding: 50px 0;
  background-color: #fff;
}

.cada-parceiro {
  position: relative;
  width: 185px;
  height: 148px;
  margin: 0 27px;
  margin-bottom: 50px;
}

.cada-parceiro > a {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
}

.cada-parceiro img {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/*Doação*/

.doacao-ativo {
  background-color: #505050 !important;
}

.section-doacao {
  position: relative;
  width: 100%;
  height: 759px;
  background: url("../../assets/site/img/fundo-doacao.jpg") center no-repeat;
}

.pai-txt-doacao {
  padding-top: 250px;
}

.link-doacao {
  width: 350px;
  text-align: center;
  height: 50px;
  line-height: 50px;
  background-color: #f7fa00;
  font-size: 28px;
  color: #8a8e1a;
  text-transform: uppercase;
  margin: 25px 0;
}

.link-doacao-plus {
  background-color: #db1d5b;
  color: #fff;
}

.link-doacao:hover {
  background-color: #505050;
  color: #fff;
}

.engloba-cartoes img {
  margin-left: 10px;
}

.engloba-cartoes img:first-child {
  margin-left: 0;
}

.section-doacao2 {
  position: relative;
  width: 100%;
  height: 759px;
  background: url("../../assets/site/img/fundo-erro.jpg") center no-repeat;
}

.left-busca {
  width: 350px;
}

.right-busca {
  width: 550px;
}

.form-search {
  width: 250px;
}

.search-input {
  width: 100% !important;
}

.filtro-busca {
  font-size: 14px;
  color: #05bde4;
  margin: 40px 0 20px 0;
  text-transform: uppercase;
}

.lista-filtro {
  margin-left: 15px;
}

.lista-filtro li {
  list-style: disc;
  color: #db1d5b;
  border-bottom: 2px solid #636363;
  padding-bottom: 2px;
  margin-bottom: 5px;
}

.lista-filtro li:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

.lista-filtro a {
  font-size: 14px;
  color: #fff;
  text-transform: uppercase;
}

.lista-filtro a:hover,
a.lista-filtro-ativo {
  color: #f7fa00 !important;
}

.engloba-texto-filtro {
  margin-bottom: 25px;
  padding-bottom: 20px;
  border-bottom: 2px solid #636363;
}

.h3-texto-filtro {
  display: table;
  font-size: 24px;
  color: #fff;
  margin-bottom: 12px;
}

.data-filtro {
  font-size: 11px;
  color: #b2b0b0;
  margin-bottom: 15px;
}

.p-texto-filtro {
  font-size: 14px;
  color: #fff;
  line-height: 20px;
  margin-bottom: 12px;
}

.link-texto-filtro {
  font-size: 14px;
  color: #db1d5b;
  display: table;
}

.data-filtro span {
  color: #db1d5b;
  font-size: 9px;
}

.link-texto-filtro:hover,
.h3-texto-filtro:hover {
  color: #f7fa00;
}

.section-inicial-seminario {
  background: url("../../assets/site/img/banners/seminario-pequena.jpg") center bottom no-repeat;
}

.section-banner-setembro-dourado {
  background: url("../../assets/site/img/fundo-setembro-dourado.jpg") center center no-repeat;
}

.section-setembro-dourado {
  font-size: 18px;
}

.section-setembro-dourado .chamada {
  font-size: 21px;
  text-align: center;
  margin-bottom: 20px;
}

.section-setembro-dourado h3 {
  font-size: 21px;
  text-align: center;
  line-height: 95px;
}

.section-setembro-dourado li,
.section-setembro-dourado p {
  margin-bottom: 15px;
  line-height: 25px;
}

.section-setembro-dourado li {
  list-style: outside disc;
}

.section-setembro-dourado a,
.section-setembro-dourado a:visited {
  display: inline;
  color: #000;
  font-weight: bold;
  font-size: 16px;
}

.section-setembro-dourado a:hover {
  text-decoration: underline;
}

.titulo-section-transparencia {
  text-align: center;
  font-size: 42px;
  padding-top: 20px;
}

.botoes-transparencia {
  display: table;
  margin: 0 auto;
  margin-bottom: 60px;
}

.botoes-transparencia li {
  margin: 0 15px;
  width: 160px;
}

.botoes-transparencia li a {
  display: block;
  width: 100%;
  background-color: #05bde4;
  padding: 10px 0;
  font-size: 20px;
  line-height: 20px;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
}

.botoes-transparencia li a:hover,
.transparencia-ativo {
  background-color: #505050 !important;
}

.cada-transparencia {
  width: 90%;
  padding-bottom: 15px;
  border-bottom: 1px solid #c5c5c5;
  margin: 0 auto;
  margin-bottom: 25px;
}

.titulo-transparencia {
  position: relative;
  color: #4a4a4a;
  font-size: 26px;
  line-height: 30px;
  cursor: pointer;
  padding-right: 35px;
}

.titulo-transparencia i {
  position: absolute;
  right: 0;
  top: 0;
  line-height: 32px;
  width: 32px;
  height: 32px;
  text-align: center;
  font-size: 24px;
}

.aparece-transparencia {
  display: none;
  margin-top: 20px;
}

.aparece-transparencia p {
  color: #898989;
  font-size: 18px;
  line-height: 24px;
}